import axios from 'axios'
import store from '../store'
import { isEmptyObject } from '@/common'
import { getCookie, deleteCookie } from '@/common/cookie'
import api from './api'
import { timestampToTime } from '@/common/getDateStr'
import JSONBig from 'json-bigint'
import { message } from 'ant-design-vue'
import router from '@/router'

const Axios = axios.create({
  method: 'POST',
  withCredentials: true,
  transformResponse: [
    (data) => {
      return JSON.stringify(JSONBig.parse(data))
    },
  ],
})

/**
 * 判断是否是时期格式
 * @param {string} date
 */
const isDate = (date) => {
  let is_date = false
  let pattern_zh = new RegExp('[\u4E00-\u9FA5]+') //判断是否有中文
  let pattern_en = new RegExp('[A-Za-z]+') //判断是否有英文
  let pattern_date = new RegExp(/^\d{4}-\d{2}-\d{2}/) //判断2020-10-10 是否存在
  if (
    !pattern_zh.test(date) &&
    !pattern_en.test(date) &&
    pattern_date.test(date) &&
    isNaN(date) &&
    !isNaN(Date.parse(date)) &&
    (date.length === 10 || date.length === 19)
  ) {
    is_date = true
  }
  return is_date
}

/**
 * 时区处理 请求加1小时，返回减1小时
 * @param {date} date
 * @param {string} type req请求 res返回
 */
const handleDate = (date, type) => {
  let timezoneDiffBeijing = localStorage.getItem('timezoneDiffBeijing') || 0
  let time = +new Date(date) + timezoneDiffBeijing * 3600 * 1000 * (type === 'req' ? 1 : -1)
  return timestampToTime(time)
}

/**
 * 时区处理 请求加1小时，返回减1小时
 * @param {object} data 对象
 * @param {string} type req请求 res返回
 */
const timeZoneProcessing = (data, type = 'req') => {
  if (isEmptyObject(data) || !data) {
    return data
  }
  Object.keys(data).forEach((item) => {
    if (typeof data[item] === 'object') {
      timeZoneProcessing(data[item], type)
    } else if (isDate(data[item])) {
      data[item] = handleDate(data[item], type)
    }
  })
  return data
}

const handlePrivilegeError = (errorData) => {
  let ipAccessed = errorData.ipAccessed
  if (!ipAccessed) {
    layer.alert('您当前所在的网络，没有访问OMS的权限哦 ^^', { icon: 6 })
    return
  }

  let partyIds = errorData.parties || []
  let privileges = errorData.privileges || []
  if (partyIds.length || privileges.length) {
    axios({
      method: 'post',
      url: api.getPrivilegesFullInfo,
      data: {
        data: {
          partyIds: partyIds,
          privileges: privileges,
        },
      },
    })
      .then((res) => {
        if (!res.success) {
          layer.alert('权限不足，查询具体权限出现异常', { icon: 6 })
          return
        }
        let rs = res.data.resources || []
        let ps = res.data.parties || []
        if (rs.length && ps.length) {
          layer.alert('权限不足，查询具体权限出现异常', { icon: 6 })
          return
        }
        let str = '权限不足：'
        if (rs.length) {
          str += '<br/>'
          str += '缺失功能权限：'
          for (let i = 0; i < rs.length; i++) {
            str += "<label style='margin:10px'>" + rs[i].name + '</label>'
          }
        }
        if (ps.length) {
          str += '<br/>'
          str += '缺失业务权限：'
          for (let i = 0; i < ps.length; i++) {
            str += "<label style='margin:10px'>" + ps[i].partyName + '</label>'
          }
        }
        layer.alert(str, { icon: 6 })
      })
      .catch(() => {
        layer.alert('权限不足，查询具体权限出现异常', { icon: 6 })
      })
  }
}

Axios.interceptors.request.use(
  (config) => {
    // loding
    if (config.hasLoading) {
      store.commit('setState', {
        loading: true,
      })
    }

    //method
    config.method = config.type || 'post'

    // path
    let path = config.url
    let requestId = Common?.guid(path)

    if (path.indexOf('?') > 0) {
      path = path + '&requestId=' + requestId
    } else {
      path = path + '?requestId=' + requestId
    }
    // partyId
    let partyId = store.getters.partyId

    if (partyId) {
      path = path + '&cpid=' + partyId
    }
    let language = localStorage.getItem('lang') || 'zh'
    let language_mapping = {
      zh: 'zh_CN',
      en: 'en_US',
      th: 'th_TH',
    }
    path += '&language=' + language_mapping[language]
    let token = getCookie('d_session_id')
    config.headers['Authorization'] = token
    config.headers['Content-Type'] = config.contentType ?? 'application/json; charset=UTF-8'
    if (config.responseType) {
      config.headers['Response-Type'] = config.responseType
    }

    let data =
      config.data === undefined
        ? JSON.stringify({})
        : config.contentType === false
        ? config.data
        : JSON.stringify(config.data)
    config.data =
      data instanceof FormData ? data : JSON.stringify(timeZoneProcessing(JSON.parse(data), 'req'))
    config.url = path

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

Axios.interceptors.response.use(
  (res) => {
    if (res.config.hasLoading) {
      store.commit('setState', {
        loading: false,
      })
    }

    const value = timeZoneProcessing(JSON.parse(res.data), 'res')
    // console.log('value: ', value)
    if (value.code == 200 || value.code == 1000000) {
      //为了兼容之前的http写法
      if (res.config.success && typeof res.config.success === 'function') {
        res.config.success(value)
      }
      return value
    }

    if ('privilege-error' == value.code) {
      handlePrivilegeError(value.data)
      return
    }

    if (+value.error_code === 5001) {
      //登录超时
      goToLogin()
      return
    }

    if (res.config.fail && typeof res.config.fail === 'function') {
      res.config.fail(value)
      return
    }

    let err = value.subMsg || value.msg || value.message
    if (err) {
      message.error(err)
    }
  },
  (error) => {
    let response = error.response
    console.log('error: ', response)
    //404 || 发布中
    if (response.status === 404) {
      // window.location.href = '/404'
      router.push('/404')
      return
    }
    store.commit('setState', {
      loading: false,
    })
    try {
      let value = JSON.parse(response?.data)
      if (+value.error_code === 5001) {
        //登录超时
        goToLogin()
        return
      }
      message.error(value.msg || value.subMsg || value.message)
    } catch (err) {
      console.log(err)
    }
  }
)

function goToLogin() {
  message.destroy()
  message.warning('登录超时')
  localStorage.clear()
  setTimeout(() => {
    deleteCookie(() => {
      window.location.href = '/login'
    })
  }, 500)
}

export default Axios
