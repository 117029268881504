import HostUrl from '@/utils/HostUrl.js'

let hostUrl = new HostUrl()

let host = hostUrl.getUrl('INVENTORY'),
  goods_host = hostUrl.getUrl('GOODS'),
  task_host = hostUrl.getUrl('REPORT'),
  order_host = hostUrl.getUrl('ORDER')

export default {
  host: host,
  dingtalk: host + '/oauth/render/dingtalk', // 钉钉二维码
  callbackDing: host + '/oauth/callback/dingtalk', //钉钉二维码
  getPrivilegesFullInfo: host + '/user/privileges', //user/privileges
  resourceCodes: host + '/privilege/all', //privilege/all

  skuPlatformStockConfigList: host + '/skuStock/skuPlatformStockConfigList', //库存查询批量设置渠道库存列表
  updatePlatformStockConfig: host + '//skuStock/updatePlatformStockConfig', //修改渠道库存比例设置
  queryCalculateGroupStock: host + '/skuStock/queryCalculateGroupStock', //查询组合商品库存
  // 库存盘点
  inventoryVerificationList: host + '/inventoryVerification/inventoryVerificationList', //库存盘点盘点单列表
  inventoryVerificationDetail: host + '/inventoryVerification/detail', //库存盘点单详情
  queryInventoryForProduct: host + '/inventoryVerification/queryInventoryForProduct', //查询barcode实际库存
  inventoryVerificationCreate: host + '/inventoryVerification/create', //新增盘点单
  inventoryVerificationUpdateInfo: host + '/inventoryVerification/updateInfo', //更新盘点信息
  inventoryVerificationUpdateItems: host + '/inventoryVerification/updateItems', //更新商品信息
  inventoryVerificationBatchCancel: host + '/inventoryVerification/batchCancel', //批量作废
  inventoryVerificationCancel: host + '/inventoryVerification/cancel', //单个作废
  importVerification: host + '//inventoryVerification/importVerification', //导入盘点数据
  inventoryVerificationConfirm: host + '//inventoryVerification/confirm', //确认调整库存
  createInventoryVerificationByApi:
    host + '//inventoryVerification/createInventoryVerificationByApi', //库存初始化
  queryGroupProportionConfig: host + '/skuStock/queryGroupProportionConfig', //修改单品/组合商品库存比例设置
  updateGroupProportionConfig: host + '//skuStock/updateGroupProportionConfig', //组合商品库存比例设置
  createSafetyStockTransform: host + '//inventoryVerification/createSafetyStockTransform', //安全库存比例设置
  safetyStockTransformConfigList: host + '//inventoryVerification/safetyStockTransformConfigList', //安全库存比例查询
  inventoryChangePrice: host + '//inventoryVerification/inventoryChangePrice', //盘盈价格修改

  //goods_api
  brandList: goods_host + '/brand/list', //获取品牌列表

  taskCreate: task_host + '/task/create', //task导出

  getSkuList: goods_host + '/sku/query', //查询sku列表
  querySkuStockTransactionList: host + '/skuStockTransaction/skuStockTransactionList', //库存流水列表

  getWaitReservedStockNum: order_host + '/order/front/lock/stock/count', //获取带预定库存
  queryCategoryList: goods_host + '/category/list', //获取商品类目列表
  createCategory: host + '/category/create', //创建类目
  queryPlatformCategoryTree: host + '/category/queryPlatformCategoryTree', //获取全平台类目树
  getAllWarehouseList: host + '/warehouse/list', //仓库列表查询                     warehouse/list
  batchSavePurchaseOrder: host + '/purchaseOrder/batchSave', // 批量导入采购单
  updateSkuStockConfig: host + '/skuStock/updateSkuStockConfig', //库存设置接口
  getSkuStockConfigList: host + '/skuStock/skuStockConfigList', //库存设置接口
  barcodeListByBuId: goods_host + '/goods/barcodeListByBuId', //根据bu查询barcode
  exportBatchFile: host + '/excel/export', //导出
  getGroupInventory: host + '/skuStock/groupStockList', //组合商品库存查询                   inventory/product_groups
  skuQuery: host + '/skuStock/skuStockList', //sku列表查询接口                sku/list
  warehouseList: host + '/warehouse/list', // 仓库列表页
  regionList: host + '/region/all', // 省、市、区查询                region/all
}
