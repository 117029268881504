function isEmptyObject(obj) {
  // 检查参数是否为对象
  if (typeof obj !== 'object' || obj === null) {
    return true
  }

  // 检查对象是否有可枚举的属性
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return false
    }
  }

  return true
}

function copy(data) {
  const url = data
  const oInput = document.createElement('input')
  oInput.value = url
  document.body.appendChild(oInput)
  oInput.select() // 选择对象;
  document.execCommand('Copy') // 执行浏览器复制命令
  layer.msg('已复制', { timeout: 800 })
  oInput.remove()
}

export { isEmptyObject, copy }
