export { getDateStr, timestampToTime, getDateDiff }

//获取当前时间
function getDateStr(AddDayCount, hms) {
  var dd = new Date()
  dd.setDate(dd.getDate() + AddDayCount) //获取AddDayCount天后的日期
  var y = dd.getFullYear()
  var M = dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1 //获取当前月份的日期，不足10补0
  var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate() //获取当前几号，不足10补0
  var H = dd.getHours() < 10 ? '0' + dd.getHours() : dd.getHours()
  var m = dd.getMinutes() < 10 ? '0' + dd.getMinutes() : dd.getMinutes()
  var s = dd.getSeconds() < 10 ? '0' + dd.getSeconds() : dd.getSeconds()
  if (hms) {
    return y + '-' + M + '-' + d + ' ' + H + ':' + m + ':' + s
  } else {
    return y + '-' + M + '-' + d
  }
}

//时间戳转换成时间格式
function timestampToTime(timestamp) {
  var dd = new Date()
  dd.setTime(timestamp)
  var y = dd.getFullYear()
  var M = dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1 //获取当前月份的日期，不足10补0
  var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate() //获取当前几号，不足10补0
  var H = dd.getHours() < 10 ? '0' + dd.getHours() : dd.getHours()
  var m = dd.getMinutes() < 10 ? '0' + dd.getMinutes() : dd.getMinutes()
  var s = dd.getSeconds() < 10 ? '0' + dd.getSeconds() : dd.getSeconds()
  return y + '-' + M + '-' + d + ' ' + H + ':' + m + ':' + s
}

//获取两个时间的时间差
function getDateDiff(startDate, endDate) {
  var s1 = new Date(startDate)
  var s2 = endDate ? new Date(endDate) : new Date()
  var time = s2.getTime() - s1.getTime()
  var days = parseInt(time / (1000 * 60 * 60 * 24))
  return days
}
